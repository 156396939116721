export const Flag = Object.freeze({
  ACTIVE: 1,
  NOT_DELETED: 0,
  REPLACE_THESIS: 1,
})

export const Status = Object.freeze({
  WAITING_APPROVAL: 0,
})

export default { Flag, Status }
